import React, { useEffect, useState, FC, SyntheticEvent } from 'react';
import { openWindow } from '~/src/helpers/open-window.helper';
import {
    sendDownloadLinkAnalytics,
    sendCustomButtonClickAnalytics,
} from '~/src/components/blocks/main/how-it-works/how-it-works.analytics';
import { ContentContainer } from '~/src/styles/shared-css/content-container';
import { TEXTS } from '~/src/components/blocks/main/how-it-works/how-it-works.constants';
import { HowItWorksStep } from '~/src/components/common-components/how-it-works-step/how-it-works-step';
import { FeedbackForm } from '~/src/components/common-components/feedback-form/feedback-form';
import { AgentIndividualInfoBlock } from '~/src/components/blocks/main/how-it-works/components/agent-individual-info-block/agent-individual-info-block';
import { getScrollbarWidth } from '~/src/helpers/get-scrollbar-width';
import HowItWorksBgLines from '~/src/images/main-how-it-works/how-it-works-bg-lines.png';
import { MainButtonVisualEnum } from '~/src/components/form-components/main-button/main-button-visual.enum';
import { SvgDownload } from '~/src/components/svg-components/download';
import * as Styled from './how-it-works.styles';

interface HowItWorksProps {
    offerUrl: string;
}

export const HowItWorks: FC<HowItWorksProps> = ({ offerUrl }) => {
    const [showFeedbackForm, setShowFeedbackForm] = useState(false);

    useEffect(() => {
        document.body.style.marginRight = showFeedbackForm ? `${getScrollbarWidth()}px` : 'unset';
        document.body.style.overflow = showFeedbackForm ? 'hidden' : 'auto';
    }, [showFeedbackForm]);

    const closeFeedbackForm = (event: KeyboardEvent) => {
        if (event instanceof KeyboardEvent && event.key === 'Escape') {
            setShowFeedbackForm(false);
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', closeFeedbackForm);
        return () => window.removeEventListener('keydown', closeFeedbackForm);
    }, []);

    const onClickDownloadLink = (event: SyntheticEvent<HTMLElement>) => {
        event.preventDefault();
        sendDownloadLinkAnalytics();
        offerUrl && openWindow(offerUrl);
    };

    const onCustomButtonClick = () => {
        sendCustomButtonClickAnalytics();
        setShowFeedbackForm(true);
    };

    return (
        <Styled.HowItWorks>
            <ContentContainer>
                <Styled.HowItWorksHeader>{TEXTS.head}</Styled.HowItWorksHeader>
                <Styled.Instruction>
                    <Styled.InstructionHeader>{TEXTS.subhead}</Styled.InstructionHeader>
                    <Styled.StepsDescription>
                        <Styled.StepDescriptionRemarque>{TEXTS.stepDescriptionRemarque}</Styled.StepDescriptionRemarque>
                        {TEXTS.stepsDescription}
                    </Styled.StepsDescription>
                    <Styled.Steps>
                        {TEXTS.steps.map((stepData) => (
                            <HowItWorksStep key={stepData.id} stepData={stepData} />
                        ))}
                    </Styled.Steps>
                    <Styled.ButtonsArea>
                        <Styled.CustomButton
                            typeVisual={MainButtonVisualEnum.alwaysWhite}
                            onClick={onCustomButtonClick}
                            textForSSR={'Получить консультацию'}
                            type={'button'}
                        >
                            Получить консультацию
                        </Styled.CustomButton>
                        <Styled.DownloadLinkWrap onClick={onClickDownloadLink}>
                            <SvgDownload />
                            <Styled.DownloadLink href={''} rel={'noopener noreferer'}>
                                {'Условия программы'}
                            </Styled.DownloadLink>
                        </Styled.DownloadLinkWrap>
                    </Styled.ButtonsArea>
                    <Styled.InstructionBgImg src={HowItWorksBgLines} width={567} height={291} alt="Полукольца" />
                </Styled.Instruction>
            </ContentContainer>
            <AgentIndividualInfoBlock />
            <FeedbackForm
                isOpen={showFeedbackForm}
                setShowFeedbackForm={setShowFeedbackForm}
                onClose={() => setShowFeedbackForm(false)}
            />
        </Styled.HowItWorks>
    );
};
