import styled, { css } from 'styled-components';
import ReactInputMask from 'react-input-mask';
import { COLORS } from '~/src/styles/tokens/colors';
import { FONTS } from '~/src/styles/style-helpers/font-helper';
import { rem } from '~/src/helpers/rem-converter.helper';
import Tooltip from '~/src/components/form-components/tooltip/tooltip';
import { BORDER_RADIUS } from '~/src/styles/tokens/border-radius';
import { SvgLock } from '~/src/components/svg-components/lock';

export const Textbox = styled.div`
    position: relative;
    width: 100%;
    flex-direction: column;
    gap: ${rem(4)};
`;

export const Label = styled.div<{ hasError: boolean; }>`
    display: flex;
    height: fit-content;
    align-items: center;
    gap: ${rem(4)};
    ${FONTS.sans.regular.size14};
    color: ${(p) => p.hasError ? COLORS.accentNegative : COLORS.textSecondary};
    cursor: default;
`;

export const TooltipWrap = styled(Tooltip)`
    height: 100%;
    align-self: center;
    padding-left: ${rem(8)};
`;

export const RequiredStar = styled.span`
    color: ${COLORS.controlsPrimaryActive};
    align-self: flex-start;
`;

export const SvgLockWrap = styled(SvgLock)`
    margin-bottom: ${rem(4)};
`;

export const Box = styled(ReactInputMask)<{ isSelectMode: boolean; isSearchIcon?: boolean; }>`
    width: 100%;
    border: 2px solid ${COLORS.controlsInactive};
    border-radius: ${BORDER_RADIUS.normal8};
    ${FONTS.sans.regular.size17};
    padding: ${rem(10)} ${rem(34)} ${rem(10)} ${rem(12)};
    box-sizing: border-box;
    color: ${COLORS.textLightPrimary};
    text-indent: ${p => p.isSearchIcon ? rem(32) : 'initial'};

    /*noinspection CssInvalidPseudoSelector*/
    &::-webkit-contacts-auto-fill-button {
        visibility: hidden;
        pointer-events: none;
        height: 0;
        width: 0;
        margin: 0;
    }

    ${(p) => p.isSelectMode && css`
        caret-color: transparent;
        cursor: unset;

        &:focus {
            border-color: ${COLORS.backgroundStroke};
        }
    `};

    &:focus-visible {
        outline: none;
    }

    &:read-only {
        cursor: default;
        pointer-events: none;
        background-color: ${COLORS.backgroundDisabled};
        opacity: .6;
    }
`;

export const BoxContainer = styled.div<{ hasError: boolean; isSelectMode: boolean; backgroundColor?: string; }>`
    position: relative;

    ${Box} {
        background-color: ${p => p.backgroundColor || COLORS.backgroundSecondary};
        border-color: ${(p) => p.hasError ? COLORS.accentNegative : COLORS.backgroundStroke};
        cursor: ${(p) => p.isSelectMode && 'pointer'};

        &:hover {
            border-color: ${COLORS.textLightPrimary};
        }

        &:focus {
            border-color: ${COLORS.textPrimaryLink};
        }

        &:read-only {
            background-color: ${COLORS.backgroundDisabled};
        }
    }
`;

export const IconContainer = styled.div<{ isClickable?: boolean; isRotated?: boolean; }>`
    position: absolute;
    right: ${rem(16)};
    top: 0;
    height: 100%;
    transform: rotateX(${(p) => p.isRotated ? '180deg' : '0deg'});
    display: flex;
    align-items: center;
    cursor: ${(p) => p.isClickable ? 'pointer' : 'default'};
    transition: transform 200ms;
`;

export const SearchIconContainer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto auto auto ${rem(12)};
    width: ${rem(24)};
    height: ${rem(24)};
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ErrorHiddenInputMessage = styled.input`
    all: unset;
    position: absolute;
    height: 0;
    width: 0;
    pointer-events: none;
    opacity: 0;
`;

export const ErrorMessageContainer = styled.div`
    color: ${COLORS.textNegative};
    ${FONTS.compact.regular.size12};
`;

export const DescriptionContainer = styled.div`
    color: ${COLORS.textSecondary};
    ${FONTS.compact.regular.size12};
`;

export const NotificationMessage = styled.span`
    color: ${COLORS.textPrimaryLink};
    ${FONTS.sans.regular.size12};
    cursor: pointer;
`;