import React, { useMemo, FC } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import NextImage from 'next/image';
import { DEVELOPERS_HOST_ENV } from '~/src/params';
import { useFetchCurrentAgentsApis } from '~/src/repositories/connected-service/hooks/use-fetch-current-apis.hook';
import { selectWorkingPartyId, selectIsAuthFinished, selectOrganizations } from '~/src/redux/user/user.selectors';
import { selectIsAuth } from '~/src/redux/id-token/id-token.selectors';
import { TEXTS_MTS_AGENTS_ABOUT_API } from '~/src/components/blocks/main/mts-agents-about-api/api-mts-agents.constants';
import { IMAGE_QUALITY } from '~/src/models/constants/image-quality.constants';
import {
    sendDownloadLinkAnalytics,
    sendCustomButtonAnalytics,
} from '~/src/components/blocks/main/mts-agents-about-api/mts-agents-about-api.analytics';
import { isContainsStatusConnectedOrganisations } from '~/src/helpers/contains-status-connected-organisations';
import { MainButtonVisualEnum } from '~/src/components/form-components/main-button/main-button-visual.enum';
import { SvgLinkToIcon } from '~/src/components/svg-components/link-to-icon';
import DocsScreenImage from '~/src/images/api-mts-agents/docs-screen.jpg';
import BgAPISection from '~/src/images/background/background-API-section.png';
import { ContentContainer } from '~/src/styles/shared-css/content-container';
import * as Styled from './mts-agents-about-api.styles';

export const MtsAgentsAboutApi: FC = () => {
    const workingPartyId = useSelector(selectWorkingPartyId);
    const isAuth = useSelector(selectIsAuth);
    const isAuthFinished = useSelector(selectIsAuthFinished);
    const organizations = useSelector(selectOrganizations);
    const dataAgentsApis = useFetchCurrentAgentsApis(workingPartyId);
    const router = useRouter();
    const { title, subtitle, linkText, buttonConnectText, buttonControlText } = TEXTS_MTS_AGENTS_ABOUT_API;
    const isGoToManagement = isAuth && isAuthFinished && dataAgentsApis && dataAgentsApis.connected;

    const isConnectApiAgents = useMemo(() => {
        return (
            isAuth &&
            isAuthFinished &&
            organizations.length &&
            !dataAgentsApis?.connected &&
            isContainsStatusConnectedOrganisations(organizations)
        );
    }, [isAuth, isAuthFinished, organizations.length, dataAgentsApis?.connected]);

    const buttonData = useMemo(() => {
        if (isConnectApiAgents) {
            return {
                text: buttonConnectText,
                url: `${DEVELOPERS_HOST_ENV}/serviceconnection/agents-api`,
            };
        }

        if (isGoToManagement) {
            return { text: buttonControlText, url: `${DEVELOPERS_HOST_ENV}/agents-api/management` };
        }
    }, [isConnectApiAgents, isGoToManagement, buttonConnectText, buttonControlText]);

    const onClickDocumentationLink = (linkText: string) => {
        sendDownloadLinkAnalytics(linkText);
    };

    const onCustomButtonClick = async (data: ReturnType<() => typeof buttonData>) => {
        if (!data) {
            return;
        }
        sendCustomButtonAnalytics(data.text);
        await router.push({ pathname: `${data.url}`, query: router.query });
    };

    return (
        <Styled.MtsAgentsAboutApi>
            <ContentContainer>
                <Styled.AgentsApiHeader>{title}</Styled.AgentsApiHeader>
                <Styled.Banner>
                    <Styled.Content isShowButton={isConnectApiAgents || isGoToManagement}>
                        <Styled.Description>{subtitle}</Styled.Description>
                        <Styled.ButtonsArea>
                            <Styled.DocumentationLink
                                href={`${DEVELOPERS_HOST_ENV}/agents-api/documentation`}
                                target={'_blank'}
                                rel={'noopener noreferer'}
                                onClick={() => onClickDocumentationLink(linkText)}
                            >
                                <SvgLinkToIcon />
                                <Styled.DownloadText>{linkText}</Styled.DownloadText>
                            </Styled.DocumentationLink>
                            {(isConnectApiAgents || isGoToManagement) && (
                                <Styled.CustomButton
                                    typeVisual={MainButtonVisualEnum.secondary}
                                    onClick={() => onCustomButtonClick(buttonData)}
                                    isLoading={!buttonData}
                                    type={'button'}
                                    textForSSR={buttonConnectText}
                                >
                                    {buttonData && buttonData.text}
                                </Styled.CustomButton>
                            )}
                        </Styled.ButtonsArea>
                    </Styled.Content>
                    <Styled.ImageContainer>
                        <Styled.ImageApi
                            width={642}
                            height={332}
                            src={DocsScreenImage}
                            alt={'Документация'}
                            quality={IMAGE_QUALITY.HIGH}
                        />
                    </Styled.ImageContainer>
                </Styled.Banner>
            </ContentContainer>
            <Styled.BgImageContainer>
                <NextImage
                    src={BgAPISection}
                    alt="Фон блока API МТС Агенты в виде желто-красно-фиолетового градиента"
                    fill
                />
            </Styled.BgImageContainer>
        </Styled.MtsAgentsAboutApi>
    );
};
