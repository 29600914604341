import styled, { css } from 'styled-components';
import NextImage from 'next/image';
import { ContentContainer } from '~/src/styles/shared-css/content-container';
import { COLORS } from '~/src/styles/tokens/colors';
import { BORDER_RADIUS } from '~/src/styles/tokens/border-radius';
import { rem } from '~/src/helpers/rem-converter.helper';
import { mediaQuery } from '~/src/styles/mixins';
import { MAX_1023, MAX_1439, MAX_1919, MAX_767 } from '~/src/styles/tokens/break-points';
import { FONTS } from '~/src/styles/style-helpers/font-helper';
import MainButton from '~/src/components/form-components/main-button/main-button';

const goToBtnStyles = css`
    background: ${COLORS.backgroundPrimary};
    color: ${COLORS.textLightPrimary};
    border: none;
`;

export const AgentIndividualInfoBlock = styled(ContentContainer)`
    margin-top: ${rem(20)};
`;

export const AgentIndividualInfoBlockContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    background: ${COLORS.constantLightBlackberry};
    border-radius: ${BORDER_RADIUS.biggest20};
    padding: ${rem(52)} ${rem(104)};
    width: 100%;

    ${mediaQuery(
        { maxWidth: MAX_1919 },
        css`
            padding: ${rem(40)} ${rem(52)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            padding: ${rem(40)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            padding: ${rem(32)} ${rem(16)} 0;
            align-items: center;
        `,
    )};
`;

export const InfoBlockTitle = styled.h3`
    ${FONTS.wide.medium.size44};
    line-height: ${rem(44)};
    color: ${COLORS.textPrimaryDark};

    ${mediaQuery(
        { maxWidth: MAX_1919 },
        css`
            ${FONTS.wide.medium.size32};
            line-height: ${rem(36)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            ${FONTS.wide.medium.size24};
            line-height: ${rem(28)};
            text-align: center;
        `,
    )};
`;

export const InfoBlockDescription = styled.p`
    margin-top: ${rem(20)};
    ${FONTS.compact.regular.size20};
    line-height: ${rem(28)};
    color: ${COLORS.textPrimaryDark};
    max-width: ${rem(763)};

    ${mediaQuery(
        { maxWidth: MAX_1919 },
        css`
            margin-top: ${rem(16)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1439 },
        css`
            max-width: ${rem(723)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            margin-top: ${rem(12)};
            ${FONTS.compact.regular.size17};
            line-height: ${rem(24)};
            text-align: center;
        `,
    )};
`;

export const GoToAgentIndividualPageBtn = styled(MainButton)`
    margin-top: ${rem(40)};
    ${FONTS.sans.medium.size17};
    line-height: ${rem(24)};
    ${goToBtnStyles};
    
    &:active {
        ${goToBtnStyles};
    }
    
    &:hover {
        ${goToBtnStyles};
    }

    ${mediaQuery(
        { maxWidth: MAX_1919 },
        css`
            margin-top: ${rem(24)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            margin-top: ${rem(40)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            margin-top: ${rem(32)};
            width: 100%;
            padding: ${rem(10)} ${rem(44)};
            height: ${rem(44)};
        `,
    )};
`;

export const AgentIndividualInfoBlockBgImage = styled(NextImage)`
    position: absolute;
    bottom: 0;
    right: ${rem(104)};
    height: auto;

    ${mediaQuery(
        { maxWidth: MAX_1919 },
        css`
            right: ${rem(95)};
            width: ${rem(237)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            right: ${rem(47)};
            width: ${rem(153)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            position: relative;
            right: unset;
            margin-top: ${rem(16)};
        `,
    )};
`;
