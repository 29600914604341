import { useCallback, useEffect, useRef } from 'react';

export function useDebouncedCallback<T extends (...args: any[]) => any>(
    callback: T,
    delay: number
) {
    const debounceTimerRef = useRef(0);

    useEffect(() => () => window.clearTimeout(debounceTimerRef.current), []);

    return useCallback(
        (...args: Parameters<T>) => {
            window.clearTimeout(debounceTimerRef.current);
            debounceTimerRef.current = window.setTimeout(() => callback(...args), delay);
        },
        [callback, delay]
    );
}
