import styled, { css } from 'styled-components';
import { rem } from '~/src/helpers/rem-converter.helper';
import { mediaQuery } from '~/src/styles/mixins';
import { FONTS } from '~/src/styles/style-helpers/font-helper';
import {
    MAX_1023,
    MAX_1279,
    MAX_1679,
    MAX_567,
    MAX_767,
    MIN_768,
} from '~/src/styles/tokens/break-points';

export const ProductsList = styled.section<{ isOrganizationConnect: boolean; }>`
    width: 100%;
    padding: ${(p) => (p.isOrganizationConnect ? rem(80) : rem(120))} 0 0;
    position: relative;

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            padding-top: ${rem(104)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_567 },
        css`
            padding-top: ${rem(80)};
        `,
    )};
`;

export const OrganizationConnectProductsList = styled.section`
    width: 100%;
    padding: ${rem(80)} 0 0;
    position: relative;

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            padding-top: ${rem(60)};
        `,
    )};
`;

export const Title = styled.h2`
    width: 50%;
    ${FONTS.wide.medium.size32};

    ${mediaQuery(
        { maxWidth: MAX_1679 },
        css`
            width: 60%;
        `,
    )};

    ${mediaQuery(
        { minWidth: MIN_768, maxWidth: MAX_1279 },
        css`
            width: 50%;
            ${FONTS.wide.medium.size24};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            width: 63.9%;
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            width: 100%;
            ${FONTS.wide.medium.size20};
        `,
    )};
`;
