import styled from 'styled-components';
import Image from 'next/image';
import { rem } from '~/src/helpers/rem-converter.helper';
import { COLORS } from '~/src/styles/tokens/colors';
import {
    dotSize,
    gapBetweenSlidesAndDots,
} from '~/src/components/common-components/carousel/carousel.constants';
import { BORDER_RADIUS } from '~/src/styles/tokens/border-radius';
import type { AdditionalContentAlignType } from '~/src/components/common-components/carousel/carousel.types';

export const CarouselWrapper = styled.div`
    position: relative;
    width: 100%;
`;

export const CarouselContainer = styled.div`
    position: relative;
    width: 100%;
`;

export const Carousel = styled.div<{ paddingLeft: number }>`
    width: 100%;
    gap: ${rem(gapBetweenSlidesAndDots)};
    padding-left: ${(p) => rem(p.paddingLeft)};
    transform: translate3d(0px, 0px, 0px);
    user-select: none;
    touch-action: manipulation;
`;

export const CarouselSlidesContainer = styled.div<{ gap: number; }>`
    display: flex;
    flex-flow: nowrap row;
    gap: ${(p) => rem(p.gap)};
    will-change: transform;
`;

export const CarouselSlide = styled.div`
    display: flex;
    width: 100%;
    flex-shrink: 0;
`;

export const CarouselDots = styled.div<{ marginLeft: number; }>`
    width: 100%;
    justify-content: center;
    gap: ${rem(6)};
    margin-left: ${(p) => rem(p.marginLeft)};
`;

export const CarouselDot = styled.div<{ isActiveSlide: boolean; }>`
    width: ${rem(dotSize)};
    height: ${rem(dotSize)};
    border-radius: ${BORDER_RADIUS.round};
    background-color: ${(p) => (p.isActiveSlide ? COLORS.iconLightPrimary : COLORS.iconsTertiary)};
`;

export const Controls = styled.div`
    display: flex;
    justify-content: end;
    gap: ${rem(18)};
`;

export const Arrow = styled(Image)`
    cursor: pointer;
`;

export const NavigationSection = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
    gap: ${rem(32)};
`;

export const AdditionalContent = styled.div<{ align: AdditionalContentAlignType; }>`
    justify-content: ${p => `flex-${p.align}`};
    align-content: center;
    flex-grow: 1;
`;
