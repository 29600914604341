import styled, { css } from 'styled-components';
import { rem } from '~/src/helpers/rem-converter.helper';
import { mediaQuery } from '~/src/styles/mixins';
import { FONTS } from '~/src/styles/style-helpers/font-helper';
import { BORDER_RADIUS } from '~/src/styles/tokens/border-radius';
import { MAX_1023, MAX_1279, MAX_1919, MAX_567, MAX_767, MIN_1920 } from '~/src/styles/tokens/break-points';
import { COLORS } from '~/src/styles/tokens/colors';

export const HowItWorksStep = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    flex-wrap: nowrap;
    gap: ${rem(24)};
    max-width: fit-content;
    ${FONTS.compact.regular.size20};

    ${mediaQuery(
        { minWidth: MIN_1920 },
        css`
            ${FONTS.compact.regular.size24};
            gap: ${rem(32)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            width: 100%;
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_567 },
        css`
            ${FONTS.sans.regular.size17};
            line-height: ${rem(24)};
        `,
    )};
`;

export const StepMark = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: ${rem(72)};
    min-height: ${rem(72)};
    border: 1px solid ${COLORS.backgroundDarkPrimary};
    border-radius: ${BORDER_RADIUS.round};

    ${mediaQuery(
        { maxWidth: MAX_1919 },
        css`
            min-width: ${rem(52)};
            min-height: ${rem(52)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            min-width: ${rem(36)};
            min-height: ${rem(36)};
        `,
    )};
`;

export const StepNumber = styled.span`
    ${FONTS.sans.bold.size28};

    ${mediaQuery(
        { maxWidth: MAX_1919 },
        css`
            ${FONTS.sans.bold.size24};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            ${FONTS.sans.bold.size16};
        `,
    )};
`;

export const CustomLink = styled.a`
    color: ${COLORS.textPrimaryLink};
    text-decoration: none;
`;

export const Text = styled.span`
    ${FONTS.compact.regular.size20};
    line-height: ${rem(28)};

    ${mediaQuery(
        { maxWidth: MAX_1279 },
        css`
            min-width: auto;
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            ${FONTS.compact.regular.size17};
        `,
    )};
`;
