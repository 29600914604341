import React, { FC, useState, useEffect, FocusEvent, useRef, useCallback } from 'react';
import { SvgSuccess } from '~/src/components/svg-components/success';
import { SvgError } from '~/src/components/svg-components/error';
import { SvgClose } from '~/src/components/svg-components/close';
import { SvgChevron } from '~/src/components/svg-components/chevron';
import { SvgSearchIcon } from '~/src/components/svg-components/dwopdown/search-icon';
import type { TextboxProps } from './textbox.types';
import * as Styled from './textbox.styles';

/**
 * Used in Module Federation
 */
const Textbox: FC<TextboxProps> = ({
    required = false,
    label,
    name,
    value = '',
    onValueChange,
    onValuePaste,
    onClear,
    error,
    validated = false,
    isReadOnly = false,
    autoComplete = 'off',
    mask = '',
    placeholder = '',
    onFocusHandler,
    onBlurHandler,
    hasDropdownStyle = false,
    isOpenedDropdown = false,
    onDropdownOpen,
    textBoxRef,
    needCheckValue,
    className,
    children,
    maxLength,
    tooltipText,
    formatChars,
    maskChar,
    beforeMaskedValueChange,
    notificationData,
    analyticsref,
    analyticsreferror,
    errorAttr = 'error',
    inputErrorRef,
    descriptionText,
    isSearchIcon,
    isHideCloseIcon,
    icon,
    isSelectMode = false,
    withOptionsDisplayIcon,
    noResetWithForm,
    backgroundColor,
    onClick,
    'data-testid': dataTestId = 'textbox',
}) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const hasNoErrorIcon = !validated || isFocused ? <SvgClose /> : <SvgSuccess analyticsref={analyticsref} />;
    const inputRef = useRef<HTMLInputElement>();

    const checkValue = () => {
        return needCheckValue ? !!value : true;
    };

    const clearInput = useCallback(() => onClear(), []);

    const boxRefFn = (input: HTMLInputElement) => {
        if (!noResetWithForm && input?.form) {
            inputRef.current = input;
            input.form.addEventListener('reset', clearInput);
        }
    };

    useEffect(() => {
        return () => inputRef?.current?.form?.removeEventListener('reset', clearInput);
    }, []);

    useEffect(() => {
        isReadOnly && onClear && onClear(name);
    }, [isReadOnly]);

    const iconClickHandler = () => {
        if (error) {
            return;
        }

        if (isFocused) {
            onClear && onClear(name);
        } else {
            !validated && onClear && onClear(name);
        }
    };

    const dropboxOpenHandle = () => {
        !isReadOnly && onDropdownOpen && onDropdownOpen();
    };

    const focusHandle = (event: FocusEvent<HTMLInputElement>) => {
        onFocusHandler && onFocusHandler(event);
        setIsFocused(true);
    };

    const blurHandle = (event: FocusEvent<HTMLInputElement>) => {
        onBlurHandler && onBlurHandler(event);
        setIsFocused(false);
    };

    const checkCloseIcon = () => {
        if (isHideCloseIcon) {
            return;
        }

        return (
            value &&
            !validated &&
            !error &&
            checkValue() &&
            (!hasDropdownStyle || !isSelectMode) &&
            !withOptionsDisplayIcon &&
            !isReadOnly
        );
    };

    const checkManyIcons = () => {
        return value && checkValue() && (!hasDropdownStyle || !isSelectMode) && !withOptionsDisplayIcon && !isReadOnly;
    };

    const maskCharacter = maskChar === null || maskChar ? maskChar : '∘';

    return (
        <Styled.Textbox onMouseDown={dropboxOpenHandle} className={className}>
            {label && (
                <Styled.Label hasError={Boolean(error)}>
                    <span>{label}</span>
                    {required && <Styled.RequiredStar>&#42;</Styled.RequiredStar>}
                    {tooltipText && <Styled.TooltipWrap hintText={tooltipText} />}
                    {isReadOnly && <Styled.SvgLockWrap />}
                </Styled.Label>
            )}
            <Styled.BoxContainer
                hasError={Boolean(error)}
                ref={textBoxRef}
                isSelectMode={isSelectMode}
                backgroundColor={backgroundColor}
            >
                <Styled.Box
                    inputRef={boxRefFn}
                    name={name}
                    mask={mask}
                    required={required}
                    autoComplete={autoComplete}
                    maskChar={maskCharacter}
                    placeholder={placeholder}
                    value={value || ''}
                    onChange={onValueChange}
                    onPaste={onValuePaste}
                    onFocus={focusHandle}
                    onBlur={blurHandle}
                    readOnly={isReadOnly}
                    isSelectMode={isSelectMode}
                    maxLength={maxLength}
                    formatChars={formatChars}
                    beforeMaskedValueChange={beforeMaskedValueChange}
                    isSearchIcon={isSearchIcon}
                    onClick={onClick}
                    data-testid={dataTestId}
                />
                {isSearchIcon && (
                    <Styled.SearchIconContainer>
                        <SvgSearchIcon />
                    </Styled.SearchIconContainer>
                )}
                {checkManyIcons() && (
                    <Styled.IconContainer isClickable={isFocused && !error} onMouseDown={iconClickHandler}>
                        {error ? (
                            <SvgError analyticsreferror={analyticsreferror} />
                        ) : isHideCloseIcon ? (
                            <></>
                        ) : (
                            hasNoErrorIcon
                        )}
                    </Styled.IconContainer>
                )}
                {checkCloseIcon() && (
                    <Styled.IconContainer isClickable onMouseDown={iconClickHandler}>
                        <SvgClose />
                    </Styled.IconContainer>
                )}
                {!checkManyIcons() && !checkCloseIcon() && icon && (
                    <Styled.IconContainer isClickable onMouseDown={iconClickHandler}>
                        {icon}
                    </Styled.IconContainer>
                )}
                {((hasDropdownStyle && isSelectMode) || withOptionsDisplayIcon) && !isReadOnly && (
                    <Styled.IconContainer isRotated={isOpenedDropdown} isClickable>
                        <SvgChevron />
                    </Styled.IconContainer>
                )}
            </Styled.BoxContainer>
            {error && (
                <>
                    <Styled.ErrorHiddenInputMessage
                        name={required ? errorAttr + '-' + name : undefined}
                        defaultValue={errorAttr}
                    />
                    <Styled.ErrorMessageContainer data-testid={`${dataTestId}-error`} ref={inputErrorRef}>
                        {error}&nbsp;
                        {notificationData && (
                            <Styled.NotificationMessage onClick={notificationData.callback}>
                                {notificationData.text}
                            </Styled.NotificationMessage>
                        )}
                    </Styled.ErrorMessageContainer>
                </>
            )}
            {descriptionText && !error && <Styled.DescriptionContainer>{descriptionText}</Styled.DescriptionContainer>}
            {children}
        </Styled.Textbox>
    );
};

export default Textbox;
