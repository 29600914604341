export const swipeThresholdMultiplier = 0.35;
export const gapBetweenSlidesAndDots = 12;
export const dotSize = 8;
export const defaultSlidesGap = 20;
export const defaultAnimationSpeed = 300;

export const carouselAlign = {
    left: 'left',
    center: 'center',
};
