export interface CheckboxProps {
    labelText?: string;
    LabelComponent?: JSX.Element;
    checked?: boolean;
    onChange?: (isChecked: boolean) => void;
    disabled?: boolean;
    className?: string;
    name?: string;
    withTooltip?: boolean;
    size?: CheckboxSizeEnum;
    indeterminate?: boolean;
    'data-testid'?: string;
}

export enum CheckboxSizeEnum {
    SMALL = 's',
    MEDIUM = 'm',
    LARGE = 'l',
}

export enum SvgTagsEnum {
    SVG = 'svg',
    PATH = 'path',
}
