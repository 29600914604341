import React, { FC, SyntheticEvent, useMemo } from 'react';
import { SvgSpinner } from '~/src/components/svg-components/spinner';
import { useClientSide } from '~/src/hooks/use-client-side.hook';
import { getColorSet } from '~/src/components/form-components/main-button/main-button.utils';
import { MainButtonVisualEnum } from '~/src/components/form-components/main-button/main-button-visual.enum';
import type { ButtonProps } from '~/src/components/form-components/main-button/main-button.types';
import * as Styled from './main-button.styles';

/**
 * Used in Module Federation
 */
const MainButton: FC<ButtonProps> = ({
    typeVisual = MainButtonVisualEnum.primary,
    children,
    isDisabled = false,
    onClick,
    isLoading = false,
    type = 'submit',
    textForSSR = '',
    className,
    'data-testid': dataTestId = 'button',
}) => {
    const onClickHandler = (event: SyntheticEvent<HTMLElement>) => {
        isLoading && event?.preventDefault();
        !isLoading && onClick && onClick(event);
    };

    const onClientSide = useClientSide();
    
    const colorsSet = useMemo(() => {
        return getColorSet(typeVisual);
    }, [typeVisual]);

    return (
        <Styled.Button
            className={className}
            colorsSet={colorsSet}
            disabled={isDisabled}
            onClick={onClickHandler}
            type={type}
            data-testid={dataTestId}
        >
            {onClientSide &&
                (isLoading ? <SvgSpinner color={colorsSet.spinnerColor}/> : children)}
            {!onClientSide && textForSSR}
        </Styled.Button>
    );
};

export default MainButton;
