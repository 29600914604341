import styled, { css } from 'styled-components';
import NextImage from 'next/image';
import { rem } from '~/src/helpers/rem-converter.helper';
import { mediaQuery } from '~/src/styles/mixins';
import { FONTS } from '~/src/styles/style-helpers/font-helper';
import { BORDER_RADIUS } from '~/src/styles/tokens/border-radius';
import { MAX_1023, MAX_1279, MAX_1919, MAX_567, MAX_767, MIN_1920 } from '~/src/styles/tokens/break-points';
import { COLORS } from '~/src/styles/tokens/colors';
import MainButton from '~/src/components/form-components/main-button/main-button';
import { Z_INDEX } from '~/src/styles/tokens/z-index';

export const HowItWorks = styled.section`
    width: 100%;
    margin-top: ${rem(120)};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            margin-top: ${rem(80)};
        `,
    )};
`;

export const HowItWorksHeader = styled.h2`
    width: 100%;
    ${FONTS.wide.medium.size58};
    text-transform: uppercase;

    ${mediaQuery(
        { maxWidth: MAX_1919 },
        css`
            ${FONTS.wide.medium.size44};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            ${FONTS.wide.medium.size28};
            line-height: ${rem(36)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            line-height: ${rem(30)};
        `,
    )};
`;

export const Instruction = styled.div`
    display: block;
    position: relative;
    width: 100%;
    margin-top: ${rem(32)};
    padding: ${rem(52)} ${rem(104)};
    background-color: ${COLORS.backgroundLightSecondary};
    border-radius: ${BORDER_RADIUS.biggest20};
    overflow: hidden;
    z-index: ${Z_INDEX.level1};

    ${mediaQuery(
        { maxWidth: MAX_1919 },
        css`
            padding: ${rem(40)} ${rem(52)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            padding: ${rem(40)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_567 },
        css`
            padding: ${rem(32)} ${rem(16)};
        `,
    )};
`;

export const InstructionBgImg = styled(NextImage)`
    position: absolute;
    top: 0;
    right: 0;
    z-index: ${Z_INDEX.under};
    width: 37%;
    height: auto;

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            width: 55%;
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            width: 64%;
        `,
    )};
`;

export const InstructionHeader = styled.h3`
    ${FONTS.wide.medium.size44};
    line-height: ${rem(44)};

    ${mediaQuery(
        { maxWidth: MAX_1919 },
        css`
            ${FONTS.wide.medium.size32};
            line-height: ${rem(36)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            ${FONTS.wide.medium.size24};
            line-height: ${rem(28)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            text-align: center;
        `,
    )};
`;

export const StepDescriptionRemarque = styled.p`
    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            ${FONTS.compact.regular.size14};
            line-height: ${rem(20)};
            text-align: center;
        `,
    )};
`;

export const StepsDescription = styled.div`
    flex-direction: column;
    margin-top: ${rem(20)};
    ${FONTS.compact.regular.size20};
    line-height: ${rem(28)};
    white-space: break-spaces;

    ${mediaQuery(
        { maxWidth: MAX_1919 },
        css`
            margin-top: ${rem(16)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            align-items: center;
            ${FONTS.compact.regular.size17};
            line-height: ${rem(24)};
            text-align: center;
        `,
    )};
`;

export const Steps = styled.div`
    display: flex;
    gap: ${rem(72)};
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
    margin-top: ${rem(32)};

    ${mediaQuery(
        { maxWidth: MAX_1919 },
        css`
            gap: ${rem(64)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1279 },
        css`
            gap: ${rem(48)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            flex-direction: column;
            gap: ${rem(32)};
            margin-top: ${rem(40)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            margin-top: ${rem(32)};
        `,
    )};
`;

export const ButtonsArea = styled.div`
    display: flex;
    align-items: center;
    margin-top: ${rem(40)};

    ${mediaQuery(
        { minWidth: MIN_1920 },
        css`
            margin-top: ${rem(32)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            margin-top: ${rem(40)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            width: 100%;
            flex-direction: column;
            align-items: center;
            margin-top: ${rem(36)};
        `,
    )};
`;

export const CustomButton = styled(MainButton)`
    width: unset;
    padding: ${rem(24)};
    ${FONTS.sans.medium.size17};
    cursor: pointer;
    border: 2px solid ${COLORS.backgroundLightStroke};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            width: 100%;
            max-width: 100%;
            padding: ${rem(10)} ${rem(44)};
            height: ${rem(44)};
        `,
    )};
`;

export const DownloadLinkWrap = styled.div`
    display: flex;
    align-items: center;
    margin-left: ${rem(36)};
    cursor: pointer;

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            margin-left: 0;
            margin-top: ${rem(28)};
        `,
    )};
`;

export const DownloadLink = styled.a`
    padding-left: ${rem(10)};
    ${FONTS.compact.medium.size20};
    line-height: ${rem(24)};
    color: ${COLORS.textPrimaryLink};
    text-decoration: none;
    outline: none;

    ${mediaQuery(
        { maxWidth: MAX_1919 },
        css`
            ${FONTS.compact.medium.size17};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            ${FONTS.compact.medium.size20};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            ${FONTS.compact.medium.size17};
        `,
    )};
`;
