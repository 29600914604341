import { useRouter } from 'next/router';
import { AgentIndividualInfoBlockContent } from './agent-individual-info-block.constants';
import AgentIndividualInfoBlockBgImg from '~/src/images/main-how-it-works/individual-block-image.png';
import * as Styled from './agent-individual-info-block.styles';

export const AgentIndividualInfoBlock = () => {
    const router = useRouter();
    const { title, description, btn } = AgentIndividualInfoBlockContent;

    const goToAgentIndividualPage = () => {
        router.push(btn.href);
    };

    return (
        <Styled.AgentIndividualInfoBlock>
            <Styled.AgentIndividualInfoBlockContent>
                <Styled.InfoBlockTitle>{title}</Styled.InfoBlockTitle>
                <Styled.InfoBlockDescription>{description}</Styled.InfoBlockDescription>
                <Styled.GoToAgentIndividualPageBtn onClick={goToAgentIndividualPage}>
                    {btn.text}
                </Styled.GoToAgentIndividualPageBtn>
                <Styled.AgentIndividualInfoBlockBgImage
                    src={AgentIndividualInfoBlockBgImg}
                    width={325}
                    height={276}
                    alt=""
                />
            </Styled.AgentIndividualInfoBlockContent>
        </Styled.AgentIndividualInfoBlock>
    );
};
