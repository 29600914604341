import React, {
    cloneElement,
    FC,
    MouseEvent,
    MutableRefObject,
    ReactElement,
    ReactNode,
    TouchEvent,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import {
    getCorrectSlideIndexes,
    getEvent,
    isTouchEvent,
} from '~/src/components/common-components/carousel/carousel.utils';
import { useOrientation } from '~/src/hooks/use-orientation.hook';
import { useDebouncedCallback } from '~/src/hooks/use-debounced-callback.hook';
import { useUpdateEffect } from '~/src/hooks/use-update-effect';
import { isClientSide } from '~/src/helpers/ssr.helper';
import { CarouselDots } from '~/src/components/common-components/carousel/components/carousel-dots/carousel-dots';
import {
    carouselAlign,
    defaultAnimationSpeed,
    defaultSlidesGap,
    swipeThresholdMultiplier,
} from '~/src/components/common-components/carousel/carousel.constants';
import arrowLeft from '~/src/images/carousel/arrow-left.jpg';
import arrowRight from '~/src/images/carousel/arrow-right.jpg';
import { Arrow } from './carousel.styles';
import {
    type AdditionalContentAlignType,
    type CarouselAlignType,
    DirectionEnum,
} from '~/src/components/common-components/carousel/carousel.types';
import * as Styled from './carousel.styles';

export type CarouselControlsRefType = {
    prevSlide: () => void;
    nextSlide: () => void;
    goToSlideIndex: (slideIndex: number) => void;
};

interface CarouselProps {
    children: ReactElement[];
    navigationWrapper?: React.ComponentType;
    gap?: number;
    /* Мультипликатор для порога, чтобы переключить слайд.
     * Формула такая: ширина слайда * мультипликатор = значение в пикселях,
     * превышение которого запустит механизм переключения слайда.
     * */
    thresholdMultiplier?: number;
    withDots?: boolean;
    withControls?: boolean;
    infinite?: boolean;
    align?: CarouselAlignType;
    additionalContent?: ReactNode;
    additionalContentAlign?: AdditionalContentAlignType;
    animationSpeed?: number;
    beforeSlideChange?: (currentIndex: number, nextIndex: number) => void;
    controlsRef?: MutableRefObject<CarouselControlsRefType>;
}

export const Carousel: FC<CarouselProps> = ({
    gap = defaultSlidesGap,
    thresholdMultiplier = swipeThresholdMultiplier,
    align = carouselAlign.left as 'left',
    animationSpeed = defaultAnimationSpeed,
    infinite = false,
    withDots = false,
    withControls = false,
    children,
    additionalContent,
    additionalContentAlign = 'end',
    beforeSlideChange,
    navigationWrapper,
    controlsRef,
}) => {
    const initialSlideIndex = infinite ? (children.length < 5 ? children.length : 5) : 0;
    const orientation = useOrientation();
    const [slideIndex, setSlideIndex] = useState(initialSlideIndex);
    const [containerLeftPadding, setContainerLeftPadding] = useState(0);
    const [slides, setSlides] = useState<ReactElement[]>(children);
    const startX = useRef(0);
    const posX1 = useRef(0);
    const posX2 = useRef(0);
    const endPos = useRef(0);
    const slideWidth = useRef(0);
    const slideHeight = useRef(0);
    const carouselRef = useRef<HTMLDivElement>(null);
    const slidesContainerRef = useRef<HTMLDivElement>(null);
    const isFullWidth = useRef(false);

    const isSwipe = useRef(false);
    const canSwitchSlide = useRef(true);
    const isRewind = useRef(false);
    const tmpPosition = useRef(0);
    const isCancelRewind = useRef(false);
    const moveDirection = useRef<DirectionEnum>();
    const nextSlideIndexAfterRewind = useRef<number>();
    const hasCanClick = useRef(true);

    const ControlsWrapper = navigationWrapper || 'div';
    let slideWidthWithGap = -slideWidth.current - gap;
    const endSlideIndexForRewind = children.length - 1 + initialSlideIndex;
    const threshold = slideWidth.current * thresholdMultiplier;

    const recalculateContainerLeftPadding = () => {
        if (carouselRef?.current && slideWidth?.current) {
            const containerWidth = carouselRef?.current.clientWidth;
            const leftAndRightPadding = containerWidth - slideWidth.current;

            setContainerLeftPadding(leftAndRightPadding / 2);
        }
    };

    const activateTransition = () => {
        if (slidesContainerRef?.current && animationSpeed) {
            slidesContainerRef.current.style.transition = `transform ${animationSpeed}ms ease 0ms`;
        }
    };

    const disableTransition = () => {
        if (slidesContainerRef?.current && animationSpeed) {
            slidesContainerRef.current.style.transition = 'transform 0ms ease 0ms';
        }
    };

    const setTrackPosition = (positionX: number) => {
        if (slidesContainerRef?.current) {
            slidesContainerRef.current.style.transform = `translate3d(${positionX}px, 0, 0)`;
        }
    };

    const switchSlide = () => {
        if (!isRewind.current) {
            slideWidthWithGap = -slideWidth.current - gap;
            const newPosition = slideIndex * slideWidthWithGap;
            setTrackPosition(newPosition);
            tmpPosition.current = newPosition;
        }
    };

    const changeSlideIndex = (nextIndex: number) => {
        if (beforeSlideChange) {
            const args = {
                nextIndex,
                slideIndex,
                infinite,
                initialSlideIndex,
                carouselChildrenLength: children.length,
            };
            const correctSlideIndexes = getCorrectSlideIndexes(args);

            if (correctSlideIndexes) {
                const { currentSlideIndex, nextSlideIndex } = correctSlideIndexes;

                beforeSlideChange(currentSlideIndex, nextSlideIndex);
            }
        }
        setSlideIndex(nextIndex);
    };

    const goToSlideIndex = (slideIndex: number) => {
        const nextSlideIndex = slideIndex + initialSlideIndex;

        activateTransition();
        changeSlideIndex(nextSlideIndex);
    };

    const toLeft = () => {
        if (!canSwitchSlide.current || (slideIndex === 0 && !infinite)) {
            return;
        }

        if (animationSpeed) {
            canSwitchSlide.current = false;
        } else if (infinite) {
            rewindForControls(DirectionEnum.LEFT);
        }

        if (!isRewind.current) {
            activateTransition();
            changeSlideIndex(slideIndex - 1);
        }
    };

    const toRight = () => {
        if (!canSwitchSlide.current || (slideIndex === children.length - 1 && !infinite)) {
            return;
        }

        if (animationSpeed) {
            canSwitchSlide.current = false;
        } else if (infinite) {
            rewindForControls(DirectionEnum.RIGHT);
        }

        if (!isRewind.current) {
            activateTransition();
            changeSlideIndex(slideIndex + 1);
        }
    };

    const onTouchStart = (e: TouchEvent | MouseEvent) => {
        disableTransition();

        const event = getEvent(e);
        startX.current = event.pageX;
        posX1.current = event.pageX;
        isSwipe.current = true;
    };

    const onTouchMove = (e: TouchEvent | MouseEvent) => {
        disableTransition();

        const event = getEvent(e);
        posX2.current = posX1.current - event.clientX;
        posX1.current = event.pageX;

        if (isSwipe.current) {
            hasCanClick.current = false;

            if (infinite) {
                endPos.current = startX.current - posX1.current;
                const lastSlideIndex = slides.length - children.length - 1;
                const pxShift = 4;
                const changeSlideIsPassed = Math.abs(endPos.current) > threshold;

                const currentSlideIsLast = slideIndex === lastSlideIndex;
                const currentSlideIsFirst = slideIndex === initialSlideIndex;

                const isTouchMoveToRight = Math.sign(endPos.current) === -1;
                const isTouchMoveToLeft = Math.sign(endPos.current) === 1;

                const needRewindToStart = currentSlideIsLast && isTouchMoveToLeft;
                const needRewindToEnd = currentSlideIsFirst && isTouchMoveToRight;

                if (changeSlideIsPassed && !isRewind.current) {
                    if (needRewindToStart) {
                        const nextSlideIndex = initialSlideIndex;
                        const newPosition =
                            nextSlideIndex * slideWidthWithGap - slideWidthWithGap - endPos.current + pxShift;

                        rewindForTouchMove(nextSlideIndex, newPosition, DirectionEnum.RIGHT);
                    }

                    if (needRewindToEnd) {
                        const nextSlideIndex = endSlideIndexForRewind;
                        const newPosition =
                            nextSlideIndex * slideWidthWithGap + slideWidthWithGap - endPos.current - pxShift;

                        rewindForTouchMove(nextSlideIndex, newPosition, DirectionEnum.LEFT);
                    }
                } else if (isRewind.current && !isCancelRewind.current && Math.abs(endPos.current) < threshold) {
                    if (moveDirection.current === DirectionEnum.LEFT) {
                        const nextSlideIndex = initialSlideIndex;
                        const newPosition = nextSlideIndex * slideWidthWithGap - endPos.current + pxShift;
                        rollback(nextSlideIndex, newPosition);
                    }

                    if (moveDirection.current === DirectionEnum.RIGHT) {
                        const nextSlideIndex = endSlideIndexForRewind;
                        const newPosition = nextSlideIndex * slideWidthWithGap - endPos.current - pxShift;
                        rollback(nextSlideIndex, newPosition);
                    }
                }
            }

            if (slidesContainerRef?.current) {
                const positionX = tmpPosition.current - posX2.current;
                tmpPosition.current = positionX;
                setTrackPosition(positionX);
            }
        }
    };

    const onTouchEnd = (e: TouchEvent | MouseEvent) => {
        const lastSlideIndex = children.length - 1;
        endPos.current = startX.current - posX1.current;
        isSwipe.current = false;

        if (isTouchEvent(e)) {
            hasCanClick.current = true;
        }

        activateTransition();

        if (nextSlideIndexAfterRewind.current && slideIndex !== nextSlideIndexAfterRewind.current) {
            changeSlideIndex(nextSlideIndexAfterRewind.current);
            nextSlideIndexAfterRewind.current = undefined;
            moveDirection.current = undefined;
            isRewind.current = false;
            return;
        }

        if (!infinite && slideIndex === 0 && startX.current < posX1.current) {
            if (slidesContainerRef?.current) {
                const newPosition = 0;
                setTrackPosition(newPosition);
                tmpPosition.current = newPosition;
            }
            return;
        }

        if (!infinite && slideIndex === lastSlideIndex && startX.current > posX1.current) {
            if (slidesContainerRef?.current) {
                const newPosition = slideWidthWithGap * lastSlideIndex;
                setTrackPosition(newPosition);
                tmpPosition.current = newPosition;
            }
            return;
        }

        if (Math.abs(endPos.current) > threshold && !isCancelRewind.current) {
            if (startX.current < posX1.current) {
                changeSlideIndex(slideIndex - 1);
            }
            if (startX.current > posX1.current) {
                changeSlideIndex(slideIndex + 1);
            }
        } else {
            switchSlide();
        }
    };

    const onMoseLeave = (e: MouseEvent) => {
        if (!isSwipe.current) {
            return;
        }

        onTouchEnd(e);
    };

    const preventClickWhileSwipe = (e: MouseEvent<HTMLDivElement>) => {
        if (!hasCanClick.current) {
            e.stopPropagation();
            hasCanClick.current = true;
        }
    };

    const onTransitionEnd = (event: React.TransitionEvent<HTMLDivElement>) => {
        disableTransition();

        if (canSwitchSlide.current) {
            return;
        }

        if (event.propertyName === 'transform' && infinite) {
            const isRewind = rewindForControls();

            if (isRewind) {
                return;
            }
        }

        canSwitchSlide.current = true;
    };

    const doRewind = (nextSlideIndex: number, newPosition: number) => {
        isRewind.current = true;
        canSwitchSlide.current = false;

        changeSlideIndex(nextSlideIndex);
        setTimeout(() => {
            setTrackPosition(newPosition);
            isRewind.current = false;
            canSwitchSlide.current = true;
        }, 5);
    };

    const rewindForControls = (direction?: DirectionEnum) => {
        const lastSlideIndex = slides.length - children.length;
        const needRewindToStart = animationSpeed
            ? slideIndex === lastSlideIndex
            : direction === DirectionEnum.RIGHT && slideIndex + 1 === lastSlideIndex;
        const needRewindToEnd = animationSpeed
            ? slideIndex === initialSlideIndex - 1
            : direction === DirectionEnum.LEFT && slideIndex - 1 === initialSlideIndex - 1;

        if (needRewindToStart) {
            const nextSlideIndex = initialSlideIndex;
            const newPosition = nextSlideIndex * slideWidthWithGap;

            doRewind(nextSlideIndex, newPosition);
            return true;
        }

        if (needRewindToEnd) {
            const nextSlideIndex = endSlideIndexForRewind;
            const newPosition = nextSlideIndex * slideWidthWithGap;

            doRewind(nextSlideIndex, newPosition);
            return true;
        }
    };

    const rewindForTouchMove = (nextSlideIndex: number, newPosition: number, moveDir: DirectionEnum) => {
        nextSlideIndexAfterRewind.current = nextSlideIndex;
        requestAnimationFrame(() => {
            setTrackPosition(newPosition);
        });
        tmpPosition.current = newPosition;
        isRewind.current = true;
        isCancelRewind.current = false;
        moveDirection.current = moveDir;
    };

    const rollback = (nextSlideIndex: number, newPosition: number) => {
        if (nextSlideIndex === slideIndex) {
            return;
        }

        nextSlideIndexAfterRewind.current = nextSlideIndex;
        requestAnimationFrame(() => {
            setTrackPosition(newPosition);
        });
        tmpPosition.current = newPosition;
        isCancelRewind.current = true;
        isRewind.current = false;
    };

    const prepareSlidesForInfinity = () => {
        const tempSlides = [...children];
        const cloneTempSlides = tempSlides.map((slide) => {
            return cloneElement(slide, { key: slide.key + '_clone_next' });
        });
        const fiveLastSlides = tempSlides
            .slice(tempSlides.length - initialSlideIndex, tempSlides.length)
            .map((slide) => {
                return cloneElement(slide, { key: slide.key + '_clone_prev' });
            });

        tempSlides.unshift(...fiveLastSlides);
        tempSlides.push(...cloneTempSlides);
        setSlides(tempSlides);
        setSlideIndex(initialSlideIndex);
    };

    const setSlidesSize = () => {
        if (slidesContainerRef?.current && carouselRef?.current) {
            adjustingSlidesWidth();
            const firstSlide = slidesContainerRef.current.firstElementChild;

            if (firstSlide instanceof HTMLElement) {
                slideWidth.current = isFullWidth.current ? carouselRef.current?.offsetWidth : firstSlide?.offsetWidth;
                slideHeight.current = isFullWidth.current
                    ? carouselRef.current?.offsetHeight
                    : firstSlide?.offsetHeight;
            }
        }
    };

    const adjustingSlidesWidth = () => {
        const isFullWidthSlides =
            // @ts-ignore
            carouselRef.current?.offsetWidth === slidesContainerRef.current?.firstElementChild?.offsetWidth;

        if (!isFullWidth.current && isFullWidthSlides) {
            isFullWidth.current = isFullWidthSlides;
        }

        if (isFullWidth.current) {
            for (const item of slidesContainerRef.current?.childNodes || []) {
                const carouselWindowWidth = carouselRef.current?.offsetWidth;
                // @ts-ignore
                item.style.width = carouselWindowWidth ? carouselWindowWidth + 'px' : '100%';
            }
        }
    };

    const renderSlidesWithWrapper = () => {
        switchSlide();
        return slides.map((slide) => {
            return <Styled.CarouselSlide key={slide.key}>{slide}</Styled.CarouselSlide>;
        });
    };

    const onChangeWindowSize = useCallback(() => {
        recalculateContainerLeftPadding();
        setSlidesSize();
        switchSlide();
    }, [slideIndex]);

    const debouncedOnChangeWindowSize = useDebouncedCallback(onChangeWindowSize, 8);

    useImperativeHandle(controlsRef, () => ({
        nextSlide: toRight,
        prevSlide: toLeft,
        goToSlideIndex,
    }));

    useEffect(() => {
        setSlidesSize();

        if (infinite) {
            prepareSlidesForInfinity();
        } else {
            setSlides(children);
        }
    }, [children]);

    useUpdateEffect(() => {
        switchSlide();
    }, [slideIndex]);

    useUpdateEffect(() => {
        onChangeWindowSize();
    }, [orientation]);

    useEffect(() => {
        if (!isClientSide()) {
            return;
        }

        let resizeObserver: ResizeObserver;
        const carouselWindow = carouselRef?.current;

        if (carouselWindow) {
            resizeObserver = new ResizeObserver(debouncedOnChangeWindowSize);
            resizeObserver.observe(carouselWindow);
        }

        return () => {
            carouselWindow && resizeObserver.unobserve(carouselWindow);
            resizeObserver.disconnect();
        };
    }, [debouncedOnChangeWindowSize]);

    // TODO изменить подсчет marginLeft CarouselDots на более очевидное, или изменить способ позиционирования
    return (
        <Styled.CarouselWrapper>
            <Styled.CarouselContainer>
                <Styled.Carousel
                    ref={carouselRef}
                    paddingLeft={align === carouselAlign.center ? containerLeftPadding : 0}
                >
                    <Styled.CarouselSlidesContainer
                        onTouchStart={onTouchStart}
                        onTouchMove={onTouchMove}
                        onTouchEnd={onTouchEnd}
                        onMouseDown={onTouchStart}
                        onMouseMove={onTouchMove}
                        onMouseUp={onTouchEnd}
                        onMouseLeave={onMoseLeave}
                        onClickCapture={preventClickWhileSwipe}
                        onTransitionEnd={onTransitionEnd}
                        ref={slidesContainerRef}
                        gap={gap}
                    >
                        {isFullWidth.current ? renderSlidesWithWrapper() : slides}
                    </Styled.CarouselSlidesContainer>
                    {withDots && (
                        <CarouselDots
                            infinite={infinite}
                            slidesQuantity={children.length}
                            currentSlideIndex={slideIndex}
                            align={align}
                            initialSlideIndex={initialSlideIndex}
                            containerLeftPadding={containerLeftPadding}
                        />
                    )}
                </Styled.Carousel>
            </Styled.CarouselContainer>
            {withControls && (
                <ControlsWrapper>
                    <Styled.NavigationSection>
                        <Styled.AdditionalContent align={additionalContentAlign}>
                            {additionalContent}
                        </Styled.AdditionalContent>
                        <Styled.Controls>
                            <Arrow src={arrowLeft} width={40} height={40} alt="Стрелка влево" onClick={toLeft} />
                            <Arrow src={arrowRight} width={40} height={40} alt="Стрелка вправо" onClick={toRight} />
                        </Styled.Controls>
                    </Styled.NavigationSection>
                </ControlsWrapper>
            )}
        </Styled.CarouselWrapper>
    );
};
